import { Controller } from 'stimulus'
import * as App from 'src/plugins/app'
import * as Sidebar from 'src/plugins/sidebar'
import * as ScrollTopButton from 'src/plugins/scrollTopButton'

export default class extends Controller {
  connect() {
    App.init();

    Sidebar.init();

    ScrollTopButton.init();
  }
}

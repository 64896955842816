class AutocompleteFormatter {
  constructor(scope) {
    this.scope = scope;
  }

  optionElement(item, escape) {
    switch (this.scope) {
      case 'patient':
        return this.formatPatient(item, escape);
    }
  }

  itemElement(item, escape) {
    switch (this.scope) {
      case 'patient':
        return '<div>' + escape(item.name) + '</div>';
    }
  }

  formatPatient({ name, email }, escape) {
    return '<div>' + escape(name) + '</div>';
    return `
      <div class="user-list-widget">
        <div class="content">
          <div class="name"> ${escape(name)} </div>
          <div class="email"> ${escape(email)} </div> 
       </div>
      </div>
    `;
  }
}

export default AutocompleteFormatter

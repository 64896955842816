import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import TomSelect from 'tom-select'
import ErrorsInterceptor from 'src/utils/errorsInterceptor'
import AutocompleteFormatter from 'src/autocomplete/AutocompleteFormatter'

export default class extends Controller {
  static values = {
    url: String,
    scope: String,
    options: Array
  };

  initialize() {
    this.formatter = new AutocompleteFormatter(this.scopeValue);
  }

  connect() {
    this.selectInstance =
      new TomSelect(this.element, this.configuration());

    //this.selectInstance.on('item_add', (item) => this.selectInstance.blur());

    //select.on('item_add', (item) => select.blur());
  }

  configuration() {
    return {
      ... this.defaultConfiguration(),
      ... this.overrideConfiguration()
    };
  }

  defaultConfiguration() {
    let self = this;
    return {
      valueField: this.valueField,
      searchField: this.searchField,
      options: this.optionsValue,
      openOnFocus: false,
      hideSelected: true,
      //closeAfterSelect: true,
      hidePlaceholder: true,
      plugins: ['remove_button'],
      render: {
        option: (data, escape) => this.formatOptionElement(data, escape),
        item: (data, escape) => this.formatItemElement(data, escape),
      },
      onBlur: () => this.onBlur(),
      onItemAdd: () => this.onItemAdd(),
      onType: (query) => this.onType(query),
      onChange: (value) => this.onChange(value),
      shouldLoad: (query) => this.shouldLoad(query),
      load: (query, callback) => this.load(query, callback)
    }
  }

  overrideConfiguration() {
    return {};
  }

  get valueField() {
    switch (this.scopeValue) {
      case 'patient':
        return 'id';
    }
  }

  get searchField() {
    switch (this.scopeValue) {
      case 'patient':
        return 'name';
    }
  }

  formatOptionElement(item, escape) {
    return this.formatter.optionElement(item, escape);
  }

  formatItemElement(item, escape) {
    return this.formatter.itemElement(item, escape);
  }

  onChange(value) {
    this.selectInstance.clearOptions();
    this.selectInstance.refreshOptions();
  }

  onType(query) {
    this.selectInstance.clearOptions();
    this.selectInstance.refreshOptions();
  }

  onBlur() {
    this.selectInstance.clearOptions();
    this.selectInstance.refreshOptions();
  }

  onItemAdd() {
    this.selectInstance.setTextboxValue('');
    this.selectInstance.refreshOptions();
}

  load(query, callback) {
    $.ajax({
      type: 'GET',
      url: this.urlValue,
      dataType: 'json',
      data: {
        query: query
      },
      success: (response) => callback(response.data),
      error: (xhr) => new ErrorsInterceptor({ xhr }, () => callback())
    });
  }

  shouldLoad(query) {
    return query.length > 0;
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['loading', 'error', 'iframe', 'iframeBox']

  connect() {

    this.iframeTarget.onload = () => {
      console.log(this.loadingTarget);

      this.visibility(this.loadingTarget, false);

      if (this.frameContentType == 'application/pdf') {
        this.visibility(this.iframeBoxTarget, true);
      } else {
        this.visibility(this.errorTarget, true);
      }
    }
  }

  visibility(element, active) {
    element.classList.toggle('d-none', !active);
  }

  get frameContentType() {
    return this.iframeTarget?.contentDocument?.contentType;
  }
}
